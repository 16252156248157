import CryptoJS from 'crypto-js';

export default {
    //随机生成指定数量的16进制key
    generatekey(num) {
        let library = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let key = "";
        for (var i = 0; i < num; i++) {
            let randomPoz = Math.floor(Math.random() * library.length);
            key += library.substring(randomPoz, randomPoz + 1);
        }
        return key;
    },

    //加密
    encrypt(word, keyStr, iv) {
        keyStr = keyStr ? keyStr : 'm7Ejf*Po*RQpg!hX8J!Yo_G2.sVtga28';
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        var srcs = CryptoJS.enc.Utf8.parse(word);
        iv = CryptoJS.enc.Utf8.parse(iv)
            // var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
        var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, iv: iv });
        // var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.NoPadding, iv: iv});
        return encrypted.toString();
    },
    //解密
    decrypt(word, keyStr, mode, iv) {
        keyStr = keyStr ? keyStr : 'm7Ejf*Po*RQpg!hX8J!Yo_G2.sVtga28';
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        var fiv = CryptoJS.enc.Utf8.parse(iv)
        if (mode == 'CBC') {
            var decrypt = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, iv: fiv }, );
        } else {
            var decrypt = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }, );
        }
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
        // return decrypt.toString(CryptoJS.enc.Utf8).toString();
    }
}